<template>
  <article class="contents">
    <div class="myPage-wrap">
      <div class="tab-nav common-type">
        <ul>
          <li
            :class="{ 'on': tab === 'profile' || tab === 'withdrawal' }"
            @click="link('app-mypage-profile')"
          >
            <a>내 정보 확인/수정</a>
          </li>
          <li
            :class="{ 'on': tab === 'passwordChange' }"
            @click="link('app-mypage-passwordChange')"
          >
            <a>비밀번호 변경</a>
          </li>
          <li
            :class="{ 'on': tab === 'actHistory' }"
            @click="link('app-mypage-actHistory')"
          >
            <a>나의 활동 내역</a>
          </li>
          <li
            :class="{ 'on': tab === 'askHistory' }"
            @click="link('app-mypage-askHistory')"
          >
            <a>1:1 문의 내역</a>
          </li>
        </ul>
      </div>

      <div class="tab-contents">
        <router-view :key="$route.fullPath" />
      </div>
    </div>

    <alert-dialog :options="alertProps" />
    <confirm-dialog :options="confirmProps" />
  </article>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      tab: 'profile',
    }
  },
  computed: {
    ...mapGetters({
      userName: 'account/getName',
    }),
  },
  watch: {
    userName(val) {
      if (val === null) {
        this.$router.push({ name: 'app-main' })
      }
    },
    $route(to) {
      this.tab = to.fullPath.split('/').reverse()[0]
    },
  },
  created() {
    this.tab = this.$route.fullPath.split('/').reverse()[0]
  },
  methods: {
    link(route) {
      if (this.$route.name !== route) {
        this.$router.push({ name: route }).catch(() => {})
      } else {
        this.$router.go(0)
      }
    },
  }
}
</script>
