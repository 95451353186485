var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    { staticClass: "contents" },
    [
      _c("div", { staticClass: "myPage-wrap" }, [
        _c("div", { staticClass: "tab-nav common-type" }, [
          _c("ul", [
            _c(
              "li",
              {
                class: {
                  on: _vm.tab === "profile" || _vm.tab === "withdrawal",
                },
                on: {
                  click: function ($event) {
                    return _vm.link("app-mypage-profile")
                  },
                },
              },
              [_c("a", [_vm._v("내 정보 확인/수정")])]
            ),
            _c(
              "li",
              {
                class: { on: _vm.tab === "passwordChange" },
                on: {
                  click: function ($event) {
                    return _vm.link("app-mypage-passwordChange")
                  },
                },
              },
              [_c("a", [_vm._v("비밀번호 변경")])]
            ),
            _c(
              "li",
              {
                class: { on: _vm.tab === "actHistory" },
                on: {
                  click: function ($event) {
                    return _vm.link("app-mypage-actHistory")
                  },
                },
              },
              [_c("a", [_vm._v("나의 활동 내역")])]
            ),
            _c(
              "li",
              {
                class: { on: _vm.tab === "askHistory" },
                on: {
                  click: function ($event) {
                    return _vm.link("app-mypage-askHistory")
                  },
                },
              },
              [_c("a", [_vm._v("1:1 문의 내역")])]
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "tab-contents" },
          [_c("router-view", { key: _vm.$route.fullPath })],
          1
        ),
      ]),
      _c("alert-dialog", { attrs: { options: _vm.alertProps } }),
      _c("confirm-dialog", { attrs: { options: _vm.confirmProps } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }